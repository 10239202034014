const SVGPricingHero = () => (
  <svg
    width="589"
    height="269"
    viewBox="0 0 589 269"
    fill="none"
    style={{ width: '100%' }}
    xmlns="http://www.w3.org/2000/svg"
    role="img"
  >
    <title>Graphic design of columns and spheres representing investment journey growth</title>
    <rect
      x="429.418"
      y="63.0801"
      width="159.339"
      height="128.491"
      fill="url(#paint0_linear_1401_11316)"
    />
    <ellipse cx="509.087" cy="63.0804" rx="79.6693" ry="16.126" fill="#202DAC" />
    <ellipse
      cx="509.091"
      cy="191.593"
      rx="79.6693"
      ry="16.126"
      fill="url(#paint1_linear_1401_11316)"
    />
    <rect
      x="286.645"
      y="103.441"
      width="159.339"
      height="107.579"
      fill="url(#paint2_linear_1401_11316)"
    />
    <ellipse cx="366.314" cy="103.441" rx="79.6693" ry="16.126" fill="#FC5C03" />
    <ellipse
      cx="366.314"
      cy="210.889"
      rx="79.6693"
      ry="16.126"
      fill="url(#paint3_linear_1401_11316)"
    />
    <rect
      x="143.783"
      y="157.79"
      width="159.339"
      height="76.6514"
      fill="url(#paint4_linear_1401_11316)"
    />
    <ellipse cx="223.452" cy="157.79" rx="79.6693" ry="16.126" fill="#FFC21A" />
    <ellipse
      cx="223.452"
      cy="233.619"
      rx="79.6693"
      ry="16.126"
      fill="url(#paint5_linear_1401_11316)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M160.098 218.315H0.759766V252.437C0.759766 261.343 36.4289 268.563 80.429 268.563C124.429 268.563 160.098 261.343 160.098 252.437V218.315Z"
      fill="url(#paint6_linear_1401_11316)"
    />
    <ellipse
      cx="80.429"
      cy="218.315"
      rx="79.6693"
      ry="16.126"
      fill="url(#paint7_linear_1401_11316)"
    />
    <g style={{ mixBlendMode: 'multiply' }}>
      <ellipse cx="366.292" cy="102.57" rx="16.0034" ry="3.52829" fill="#D9D9D9" />
    </g>
    <g style={{ mixBlendMode: 'multiply' }}>
      <ellipse cx="223.452" cy="154.833" rx="13.3154" ry="2.95611" fill="#D9D9D9" />
    </g>
    <circle cx="366.292" cy="82.9661" r="19.5964" fill="url(#paint8_radial_1401_11316)" />
    <circle cx="223.452" cy="140.322" r="14.5111" fill="url(#paint9_radial_1401_11316)" />
    <g style={{ mixBlendMode: 'multiply' }}>
      <ellipse cx="509.088" cy="61.4232" rx="22.4902" ry="4.14927" fill="#D9D9D9" />
    </g>
    <circle cx="509.088" cy="30.7938" r="30.6292" fill="url(#paint10_radial_1401_11316)" />
    <circle cx="81.3718" cy="180.17" r="6.25463" fill="url(#paint11_radial_1401_11316)" />
    <g style={{ mixBlendMode: 'multiply' }}>
      <ellipse cx="80.43" cy="215.969" rx="7.19757" ry="2.34572" fill="#D9D9D9" />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1401_11316"
        x1="429.418"
        y1="127.326"
        x2="523.785"
        y2="127.326"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#061392" />
        <stop offset="1" stopColor="#808BF3" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1401_11316"
        x1="429.422"
        y1="191.593"
        x2="523.789"
        y2="191.593"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#061392" />
        <stop offset="1" stopColor="#808BF3" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1401_11316"
        x1="286.645"
        y1="157.23"
        x2="381.012"
        y2="157.23"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#061392" />
        <stop offset="1" stopColor="#808BF3" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1401_11316"
        x1="286.645"
        y1="210.889"
        x2="381.012"
        y2="210.889"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#061392" />
        <stop offset="1" stopColor="#808BF3" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1401_11316"
        x1="143.783"
        y1="196.116"
        x2="238.151"
        y2="196.116"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#061392" />
        <stop offset="1" stopColor="#808BF3" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_1401_11316"
        x1="143.783"
        y1="233.619"
        x2="238.15"
        y2="233.619"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#061392" />
        <stop offset="1" stopColor="#808BF3" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_1401_11316"
        x1="0.759765"
        y1="243.439"
        x2="95.1271"
        y2="243.439"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#061392" />
        <stop offset="1" stopColor="#808BF3" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_1401_11316"
        x1="207.845"
        y1="187.88"
        x2="181.052"
        y2="292.671"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFCA39" />
        <stop offset="1" stopColor="#FC6B1A" />
      </linearGradient>
      <radialGradient
        id="paint8_radial_1401_11316"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(378.164 69.4189) rotate(130.257) scale(32.274)"
      >
        <stop stopColor="#D2D7FF" />
        <stop offset="1" stopColor="#808BF3" />
      </radialGradient>
      <radialGradient
        id="paint9_radial_1401_11316"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(232.172 130.137) rotate(138.945) scale(24.4967 18.7317)"
      >
        <stop stopColor="#FFD6BF" />
        <stop offset="1" stopColor="#FF823C" />
      </radialGradient>
      <radialGradient
        id="paint10_radial_1401_11316"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(527.533 11.5904) rotate(132.748) scale(50.0395)"
      >
        <stop stopColor="#EEEDF9" />
        <stop offset="1" stopColor="#AEACC9" />
      </radialGradient>
      <radialGradient
        id="paint11_radial_1401_11316"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(86.0984 178.459) rotate(135.28) scale(11.3205)"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#DCDCDC" />
      </radialGradient>
    </defs>
  </svg>
);

// eslint-disable-next-line import/no-default-export
export default SVGPricingHero;
