import { darken } from 'polished';
import { GatsbyLink, PartnerLink } from 'components/Links';
import { Tooltip } from 'components/Tooltip/Tooltip';

import Checkmark from 'images/icons/empty-checkmark';

import { theme } from 'styled/theme';

import type { ReactNode } from 'react';
import type { LocalePlan, PlanData } from 'page-data/pricing';
import type { Highlight } from 'styled/types';
import type { TableColumn, TableRow } from 'components/Table';

const isVisible = (cells: ReactNode[]): boolean =>
  // At least one cell (not including the first) should be true
  cells.slice(1).some(cell => cell);

interface BuildRowProps {
  /** Label for this row (left most cell) */
  label: ReactNode;
  /** Property in the plan data to use for this row */
  property: keyof PlanData;
  /** The full, localized pricing plan */
  pricingPlan: LocalePlan;
  tooltip?: ReactNode;
}

/**
 * Builds a pricing table row
 */
export const buildRow = ({ label, property, pricingPlan, tooltip }: BuildRowProps): TableRow => {
  const cells: ReactNode[] = [
    <>
      <p>{label}</p>
      {tooltip && <Tooltip overlay={tooltip} />}
    </>,
    pricingPlan.Free[property],
    pricingPlan.Starter[property],
    pricingPlan.Investor[property],
    pricingPlan.Expert[property],
  ];
  return {
    id: String(property),
    cells,
    shouldRender: isVisible,
  };
};

const LABEL_WIDTH = 40;
const TIER_WIDTH = (100 - LABEL_WIDTH) / 4;

const renderTick =
  (color?: Highlight) =>
  // eslint-disable-next-line react/display-name
  (data: ReactNode) => {
    const checkmarkStrokeColor = color ? darken(0.15, theme.colors[color]) : '#000';

    return data ? <Checkmark color={checkmarkStrokeColor} hasMarginLeft /> : '';
  };

export const coreColumns: TableColumn[] = [
  { id: 'Core', title: 'Core', width: LABEL_WIDTH },
  { id: 'Free', title: <h3>Free</h3>, width: TIER_WIDTH },
  { id: 'Starter', title: <h3>Starter</h3>, width: TIER_WIDTH, accentColor: 'orangeHighlight' },
  { id: 'Investor', title: <h3>Investor</h3>, width: TIER_WIDTH, accentColor: 'primaryHighlight' },
  { id: 'Expert', title: <h3>Expert</h3>, width: TIER_WIDTH, accentColor: 'navyBlueHighlight' },
];

export const portfolioColumns: TableColumn[] = [
  { id: 'Portfolio', title: 'Portfolio', width: LABEL_WIDTH },
  { id: 'Free', mapData: renderTick(), width: TIER_WIDTH },
  {
    id: 'Starter',
    mapData: renderTick('orangeHighlight'),
    width: TIER_WIDTH,
    accentColor: 'orangeHighlight',
  },
  {
    id: 'Investor',
    mapData: renderTick('primaryHighlight'),
    width: TIER_WIDTH,
    accentColor: 'primaryHighlight',
  },
  {
    id: 'Expert',
    mapData: renderTick('navyBlueHighlight'),
    width: TIER_WIDTH,
    accentColor: 'navyBlueHighlight',
  },
];

export const reportsColumns: TableColumn[] = [
  { id: 'Reports', title: 'Reports', width: LABEL_WIDTH },
  { id: 'Free', mapData: renderTick(), width: TIER_WIDTH },
  {
    id: 'Starter',
    mapData: renderTick('orangeHighlight'),
    width: TIER_WIDTH,
    accentColor: 'orangeHighlight',
  },
  {
    id: 'Investor',
    mapData: renderTick('primaryHighlight'),
    width: TIER_WIDTH,
    accentColor: 'primaryHighlight',
  },
  {
    id: 'Expert',
    mapData: renderTick('navyBlueHighlight'),
    width: TIER_WIDTH,
    accentColor: 'navyBlueHighlight',
  },
];

export const coreRowData: { label: ReactNode; property: keyof PlanData; tooltip?: ReactNode }[] = [
  {
    label: 'Portfolios',
    property: 'portfolios',
  },
  {
    label: 'Holdings',
    property: 'holdings',
  },
  {
    label: 'Custom groups',
    property: 'customGroups',
  },
  {
    label: 'Customer support',
    property: 'supportLevel',
  },
];

export const portfolioRowData: {
  label: ReactNode;
  property: keyof PlanData;
  tooltip?: ReactNode;
}[] = [
  {
    label: 'Supported markets',
    property: 'supportedMarkets',
    tooltip: (
      <>
        See our full list of markets{' '}
        <GatsbyLink to="/supported-stock-exchanges-managed-funds-mutual-funds">here</GatsbyLink>
      </>
    ),
  },
  {
    label: 'Automatic dividends & adjustments',
    property: 'automaticAdjustments',
    tooltip: (
      <>
        Also track your dividend
        <br />
        reinvestments (DRPs/DRIPs)
      </>
    ),
  },
  {
    label: 'Price & portfolio email alerts',
    property: 'priceAndPortfolioAlerts',
  },
  {
    label: 'Trade confirmation email imports',
    property: 'tradeConfirmationEmails',
    tooltip: (
      <p>
        Forward your trade confirmation
        <br />
        emails for{' '}
        <PartnerLink
          to="/partners/contract-notes/"
          title="Import trades via your broker's trade confirmations | Sharesight"
        >
          automatic trade importing
        </PartnerLink>
      </p>
    ),
  },
  {
    label: 'Record unlisted investments',
    property: 'recordUnlistedInvestments',
  },
  {
    label: 'Portfolio sharing',
    property: 'portfolioSharing',
  },
  {
    label: 'Benchmarking',
    property: 'benchmarking',
  },
  {
    label: 'File attachments',
    property: 'fileAttachments',
  },
  {
    label: 'Cash accounts',
    property: 'cashAccounts',
    tooltip: (
      <>
        Track your cash accounts so
        <br />
        they’re incorporated into your
        <br />
        overall portfolio value
      </>
    ),
  },
  {
    label: 'Labels & report filtering',
    property: 'labelsReportFiltering',
  },
  {
    label: 'Xero integration',
    property: 'xeroIntegration',
    tooltip: (
      <>
        Connect to your{' '}
        <PartnerLink to="partners/xero" title="Xero | Sharesight">
          Xero Account
        </PartnerLink>{' '}
        to
        <br />
        synchronise trade data and cash
        <br />
        accounts. This feature requires
        <br />
        two-factor authentication to be
        <br />
        enabled.
      </>
    ),
  },
];

export const reportsRowData: { label: ReactNode; property: keyof PlanData; tooltip?: ReactNode }[] =
  [
    {
      label: 'Performance',
      property: 'performanceReport',
      tooltip: (
        <>
          Shows total portfolio returns <br />
          over any date range.
        </>
      ),
    },
    {
      label: 'Taxable Income',
      property: 'taxableIncomeReport',
      tooltip: (
        <>
          Shows dividend and interest <br />
          payments along with the relevant <br />
          totals for tax purposes.
        </>
      ),
    },
    {
      label: 'Historical Cost',
      property: 'historicalCostReport',
      tooltip: (
        <>
          Shows opening and closing balances <br />
          at cost price over any period along <br />
          with the closing market value. <br />
          Designed for balance sheet preparation.
        </>
      ),
    },
    {
      label: 'Sold Securities',
      property: 'soldSecuritiesReport',
      tooltip: (
        <>
          Shows the total return on <br />
          each security sold within <br />
          the selected date range.
        </>
      ),
    },
    {
      label: 'All Trades',
      property: 'allTradesReport',
      tooltip: (
        <>
          Lists all trades over the <br />
          selected date range.
        </>
      ),
    },
    {
      label: 'Diversity',
      property: 'diversityReport',
      tooltip: (
        <>
          Shows portfolio diversity across different <br />
          investment sectors, investment types, <br />
          countries and markets.
        </>
      ),
    },
    {
      label: 'Exposure',
      property: 'exposureReport',
      tooltip: (
        <>
          Shows true portfolio exposure to industries, investment types <br />
          and sectors by listing your holdings alongside any assets <br />
          held within exchange traded funds (ETFs).
        </>
      ),
    },
    {
      label: 'Capital Gains Tax',
      property: 'capitalGainsTaxReport',
      tooltip: (
        <>
          Calculates capital gains from a <br />
          Capital Gains Tax (CGT) perspective.
        </>
      ),
    },
    {
      label: 'Unrealised Capital Gains Tax',
      property: 'unrealisedCapitalGainsReport',
      tooltip: (
        <>
          Calculates unrealised capital gains <br />
          as at any date. Useful for scenario <br />
          analysis and illustrating unrealised <br />
          Capital Gains Tax (CGT) liability.
        </>
      ),
    },
    {
      label: 'Future Income',
      property: 'futureIncomeReport',
      tooltip: (
        <>
          Shows expected upcoming <br />
          dividends and interest payments <br />
          to help predict cash flow.
        </>
      ),
    },
    {
      label: 'Contribution Analysis',
      property: 'contributionAnalysisReport',
      tooltip: (
        <>
          Shows how each holdings or asset <br />
          allocations have contributed to the <br />
          overall portfolio performance.
        </>
      ),
    },
    {
      label: 'Foreign Investment Fund (FIF)',
      property: 'foreignInvestmentFundReport',
      tooltip: (
        <>
          Calculates tax liability under <br />
          the FIF rules. Calculated for <br />
          both the Comparative Value and <br />
          Fair Dividend Rate methods.
        </>
      ),
    },
    {
      label: 'Traders Tax',
      property: 'tradersTaxReport',
      tooltip: (
        <>
          Calculates gains on sales from <br />a tax perspective. Designed for <br />
          traders who hold shares on <br />
          revenue account.
        </>
      ),
    },
    {
      label: 'Multi-Currency Valuation',
      property: 'multiCurrencyValuationReport',
      tooltip: (
        <>
          Compares the value of each holding <br />
          by investment type, country or market <br />
          over any period or currency.
        </>
      ),
    },
    {
      label: 'Multi-Period',
      property: 'multiPeriodReport',
      tooltip: (
        <>
          Compares portfolio returns over <br />
          different periods including the <br />
          impact of sold securities.
        </>
      ),
    },
  ];
