import { useMemo } from 'react';
import { useLocalizationContext } from '@sharesight/gatsby-plugin-sharesight-localization';
import { useMediaQuery } from 'styled-breakpoints/use-media-query';
import { useTheme } from 'styled-components';

import { pricingPlans } from 'page-data/pricing';

import { Table } from 'components/Table';

import {
  buildRow,
  coreColumns,
  coreRowData,
  portfolioColumns,
  portfolioRowData,
  reportsColumns,
  reportsRowData,
} from './tableData';

import type { FC } from 'react';
import type { TableRow } from 'components/Table';

export const PricingTable: FC = () => {
  const {
    currentLocale: { id },
  } = useLocalizationContext();

  const pricingPlan = pricingPlans[id];

  const coreRows: TableRow[] = useMemo(
    () => coreRowData.map(row => buildRow({ pricingPlan, ...row })),
    [pricingPlan]
  );

  const portfolioRows: TableRow[] = useMemo(
    () => portfolioRowData.map(row => buildRow({ pricingPlan, ...row })),
    [pricingPlan]
  );

  const reportsRows: TableRow[] = useMemo(
    () => reportsRowData.map(row => buildRow({ pricingPlan, ...row })),
    [pricingPlan]
  );

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  if (isMobile) return null;

  return (
    <>
      <Table columns={coreColumns} rows={coreRows} marginTop={6} />
      <Table columns={portfolioColumns} rows={portfolioRows} marginTop={6} />
      <Table columns={reportsColumns} rows={reportsRows} marginTop={6} />
    </>
  );
};
